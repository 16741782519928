





import axios from "axios";
import { Component, Vue } from "vue-property-decorator";

const logoutUrl = "/accounts/logout/";

@Component
export default class LogOut extends Vue {
  async postLogOut() {
    try {
      const resp = await axios.post(logoutUrl);
    } catch {
      window.location.assign(logoutUrl);
      return;
    }
    window.location.assign("/");
  }
}
